import React from "react";
import { graphql, Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";

import Layout from "../components/Layout";
import SearchEngineOptimization from "../components/SEO";
import ValueProps from "../components/Repeating/ValueProps";
import Testimonials from "../components/Repeating/Testimonials";
import Resources from "../components/Repeating/Resources";
import RecentBlogPosts from "../components/Repeating/RecentBlogPosts";
import CallToAction from "../components/Repeating/CTA";
import ButtonSolid from "../components/Button/ButtonSolid";
import {
  Accordion,
  AccordionItem,
  AccordionItemButton,
  AccordionItemPanel,
  AccordionItemState,
} from "react-accessible-accordion";

const Page = ({ data }) => {
  const faqs = [
    {
      question: "ARE THERE DIFFERENT STANDARDS FOR SELF-EMPLOYED BORROWERS?",
      answer: (
        <>
          <p>
            Because self-employed borrowers don’t have a W-2, there is more
            documentation required to prove income and verify the business.
            However, the rest of the loan process, and standards, are the same
            as they would be for borrowers who work for employers.
          </p>
        </>
      ),
    },
    {
      question: "WILL I PAY A HIGHER INTEREST RATE BECAUSE I’M SELF-EMPLOYED?",
      answer: (
        <>
          <p>
            Lenders are taking on added risk when loaning money to someone
            self-employed who seeks a mortgage and may increase the interest
            rate to account for that.
          </p>
        </>
      ),
    },
    {
      question: "HOW CAN I PREPARE BEFORE APPLYING FOR A LOAN?",
      answer: (
        <>
          <p>To become attractive to lenders, you’ll want to:</p>
          <ul className="list-disc pl-6">
            <li>Check your credit report and resolve any discrepancies</li>
            <li>
              Improve your debt-to-income ratio by increasing your income and/or
              reducing your debt
            </li>
            <li>Save up a large down payment</li>
            <li>Put significant cash reserves in the bank</li>
            <li>Make sure you have all the required documentation</li>
          </ul>
        </>
      ),
    },
  ];

  return (
    <Layout>
      <SearchEngineOptimization
        title="Self-Employed Home Loans | Accel Mortgage | Pasadena Brokers"
        description="Are you looking for self-employed home loans in Pasadena and Los Angeles County? Accel Mortgage has the info and expertise you need. Learn more!"
        // openGraphImage={data.openGraphImage.publicURL}
        // twitterOpenGraphImage={data.twitterOpenGraphImage.publicURL}
      />

      <section className="mb-20 md:mb-24 md:pt-6">
        <div className="container">
          <div className="grid items-center gap-y-10 md:grid-cols-2 md:gap-x-10 lg:gap-x-20">
            <div className="order-2 md:order-1">
              <h1>Self-Employed Home Loans: When You Work For Yourself</h1>
              <p>
                When you’re self-employed, lenders will evaluate you the same
                way they would any other type of borrower—they’ll look at your
                credit score and debt-to-income ratio to determine if you can
                pay back the loan. The only real difference is how you prove
                your income. Learn how it works so you can realize your dream of
                owning a home in California.
              </p>
              <div className="flex flex-wrap items-center space-y-6 md:flex-nowrap md:space-y-0 md:space-x-4">
                <ButtonSolid
                  href="/request-rates/"
                  text="Get a Rate Quote"
                  className="w-full md:w-auto"
                />
                <ButtonSolid
                  href="https://markdarling.floify.com/apply-now"
                  text="Get Pre-Approved"
                  outboundLink={true}
                  altStyle={2}
                  className="w-full md:w-auto"
                />
              </div>
            </div>
            <div className="order-1 md:order-2">
              <StaticImage
                src="../images/9.0 Self Employed/1.0 Hero.jpg"
                loading="eager"
                width={560}
              />
            </div>
          </div>
        </div>
      </section>

      <section className="mb-20 md:mb-32">
        <div className="container">
          <header className="mb-16 md:mb-20">
            <h2>What Lenders Like To See</h2>
          </header>
          <div className="grid gap-x-10 gap-y-16 md:grid-cols-2 lg:gap-x-20">
            <div className="flex space-x-5">
              <div className="number-text-stroke">1</div>
              <div>
                <h3 className="heading-four mb-2">Strong Credit Score</h3>
                <p className="mb-0">
                  Lenders will check your personal credit score and also the
                  credit rating for your business, if applicable..
                </p>
              </div>
            </div>

            <div className="flex space-x-5">
              <div className="number-text-stroke">2</div>
              <div>
                <h3 className="heading-four mb-2">Stable Income</h3>
                <p className="mb-0">
                  You should have at least 2 years under your belt and be able
                  to show that you have steady work.
                </p>
              </div>
            </div>

            <div className="flex space-x-5">
              <div className="number-text-stroke">3</div>
              <div>
                <h3 className="heading-four mb-2">Cash Reserves in the Bank</h3>
                <p className="mb-0">
                  If times do get tight, lenders want to see that you have an
                  emergency fund to ride out the storm.
                </p>
              </div>
            </div>

            <div className="flex space-x-5">
              <div className="number-text-stroke">4</div>
              <div>
                <h3 className="heading-four mb-2">Minimal Debt</h3>
                <p className="mb-0">
                  Your debt-to-income ratio should be lower than 43 percent,
                  which is typically the highest DTI that lenders allow.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="mb-20 md:mb-32">
        <div className="container">
          <div className="grid items-center gap-y-10 md:grid-cols-2 md:gap-x-10 lg:gap-x-20">
            <div className="order-2 md:order-1">
              <h2>The Documentation You’ll Need</h2>
              <p>Here’s what lenders will typically ask you to provide:</p>
              <ul className="styled-list-arrow">
                <li>
                  Proof of income, such as tax returns and/or bank statements
                </li>
                <li>
                  Declaration of assets, including your savings and investment
                  accounts
                </li>
                <li>
                  A list of debts, including how much you pay towards your debt
                  each month
                </li>
                <li>
                  Documentation showing how much you currently pay for your
                  mortgage or rent
                </li>
                <li>
                  A list of any other income you receive, such as alimony,
                  Social Security, or rental income
                </li>
                <li>
                  A business license or other documentation that proves you’re
                  self-employed
                </li>
              </ul>
              <div className="flex flex-wrap items-center space-y-6 md:flex-nowrap md:space-y-0 md:space-x-4">
                <ButtonSolid
                  href="/request-rates/"
                  text="Get a Rate Quote"
                  className="w-full md:w-auto"
                />
                <ButtonSolid
                  href="https://markdarling.floify.com/apply-now"
                  text="Get Pre-Approved"
                  outboundLink={true}
                  altStyle={2}
                  className="w-full md:w-auto"
                />
              </div>
            </div>
            <div className="order-1 md:order-2">
              <StaticImage
                src="../images/9.0 Self Employed/Self Employed.jpg"
                loading="lazy"
                width={560}
                className="z-0 rounded-6xl"
              />
            </div>
          </div>
        </div>
      </section>

      <section className="mb-20 md:mb-32">
        <div className="container">
          <header className="mb-8 md:mb-10">
            <h2>Frequently Asked Questions</h2>
          </header>
          <Accordion
            allowZeroExpanded={true}
            className="border-t border-gray-700/20"
          >
            {faqs.map((faq, i) => {
              return (
                <div key={i}>
                  <AccordionItem
                    uuid={i}
                    className="border-b border-gray-700/20 py-6 md:py-8"
                  >
                    <AccordionItemButton className="flex justify-between space-x-10 focus:outline-none md:space-x-20">
                      <AccordionItemState>
                        {(state) => {
                          return (
                            <>
                              <p className="heading-five mb-0">
                                {faq.question}
                              </p>

                              <i
                                className={`far ${
                                  state.expanded
                                    ? "fa-chevron-up"
                                    : "fa-chevron-down"
                                } text-base text-gray-700/50`}
                              ></i>
                            </>
                          );
                        }}
                      </AccordionItemState>
                    </AccordionItemButton>
                    <AccordionItemPanel className="pt-6 pr-10 md:pr-20">
                      {faq.answer}
                    </AccordionItemPanel>
                  </AccordionItem>
                </div>
              );
            })}
          </Accordion>
        </div>
      </section>

      <Resources />
      <Testimonials />
      <ValueProps />
      <RecentBlogPosts />
      <CallToAction />
    </Layout>
  );
};

export const data = graphql`
  {
    openGraphImage: file(
      relativePath: { eq: "Open Graph/Facebook/Homepage.jpg" }
    ) {
      publicURL
    }
    twitterOpenGraphImage: file(
      relativePath: { eq: "Open Graph/Twitter/Homepage.jpg" }
    ) {
      publicURL
    }
  }
`;

export default Page;
